import {
  useFormFieldGroup,
  useFormSection
} from "@/components/FormBuilder/Helpers";
import { FormBlock } from "../../components/FormBuilder/types";
import { useField } from "../../components/FormBuilder/Helpers/fields/useField";
export const emergencyModeForm: FormBlock[] = [
  ...useFormSection([
    ...useFormFieldGroup(
      [
        useField(
          {
            key: "isInEmergencyMaintenanceMode",
            type: "checkbox",
            label: "Enable",
            required: false
          },
          ["mb-6"]
        ),
        useField({
          key: "emergencyMaintenanceModeMessage",
          label: "Maintenance mode text",
          placeholder: "Maintenance mode text",
          required: true,
          type: "textarea"
        })
      ],
      { layout: "full" }
    )
  ])
];
