var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('el-row',{staticClass:"mb-8 border-b border-gray-200 py-8"},[_c('div',[_c('p',{staticClass:"font-medium flex items-center text-red-600"},[_c('font-awesome-icon',{staticClass:"mr-1 text-red-600",attrs:{"icon":['fal', 'exclamation']}}),_vm._v(" Maintenance Mode "),(_vm.editing?.isInEmergencyMaintenanceMode)?_c('span',{staticClass:"text-xs ml-4 text-green-400"},[_vm._v(_vm._s(_vm.__getTextUcFirst("simple", "enabled")))]):_c('span',{staticClass:"text-xs ml-4 text-red-400"},[_vm._v(_vm._s(_vm.__getTextUcFirst("simple", "disabled")))])],1),_c('p',{staticClass:"mb-4",domProps:{"innerHTML":_vm._s(_vm.__getText('global', _vm.maintenanceModeText))}}),_c('div',{staticClass:"flex justify-end"},[(_vm.showSaveButton && _vm.validationData.formIsValid)?_c('IAButton',{attrs:{"click":_vm.updateEmergencyModeData,"title":"Update Emergency Mode","type":"danger","size":"mini"}}):_vm._e()],1),_c('form-builder',{ref:"emergencyModeRef",attrs:{"schemas":_vm.emergencyModeFormSchema,"formValues":_vm.editing || {}},on:{"formFieldChanged":_vm.formFieldChangeHandler,"validationChanged":function($event){_vm.validationData = $event}}})],1)]),_c('ModalMessage',{attrs:{"name":"maintenanceModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"","description":_vm.promptMessage,"showClose":true,"buttons":[
        {
          text: `Okay`,
          classList: [''],
          click: () => _vm.doUpdate()
        },
        {
          text: `Cancel`,
          classList: ['bg-red-700'],
          click: () => _vm.$modal.hide('maintenanceModal')
        }
      ]}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }