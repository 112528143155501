
import Vue from "vue";
import { objectDifference } from "@/helpers";
import { has } from "lodash";
import {
  settingMapActions,
  settingMapMutations,
  settingMapState
} from "@/store/modules/setting";
import { getSettingsDefaults } from "@/helpers/defaultObjectGenerators";
import { authMapState } from "@/store/modules/auth";
import { settingsBase } from "@/helpers/defaultObjects";
import { emergencyModeForm } from "@/forms/admin/emergencyMode";
import { ISetting } from "@/store/modules/setting/types";

interface IData {
  message: string;
  error: string;
  loading: boolean;
  isDefault: boolean;
  promptMessage: string;
  validationData: Record<string, any>;
}

export default Vue.extend({
  name: "MaintenanceModeEditor",
  data(): IData {
    return {
      message: "",
      error: "",
      isDefault: false,
      loading: false,
      promptMessage: "",
      validationData: {}
    };
  },
  mounted() {
    this.setEdit(settingsBase());
    if (this.getSettingsData) {
      const {
        isDefault,
        emergencyMaintenanceModeMessage,
        isInEmergencyMaintenanceMode
      } = this.getSettingsData;

      this.editField({
        key: "isDefault",
        value: isDefault
      });
      this.editField({
        key: "emergencyMaintenanceModeMessage",
        value: emergencyMaintenanceModeMessage
      });
      this.editField({
        key: "isInEmergencyMaintenanceMode",
        value: isInEmergencyMaintenanceMode
      });
    }
  },
  destroyed() {
    this.setEdit({} as any);
  },
  methods: {
    ...settingMapState(["makingApiRequest"]),
    ...settingMapActions(["updateDefaultSettings"]),
    ...settingMapMutations({
      setEdit: "SET_EDIT",
      editField: "SET_EDIT_FIELD"
    }),
    formFieldChangeHandler(data: { key: keyof ISetting; value: any }) {
      const { key, value } = data;
      this.editField({
        key,
        value
      });
    },
    async updateEmergencyModeData(): Promise<void> {
      const enableText =
        "You are about to enable emergency maintenance mode. This will prevent all users other than admin from accessing the website.";
      const disableText =
        "You are about to disable emergency maintenance mode. This will enable all users access to the website as usual";
      this.promptMessage = this.editing?.isInEmergencyMaintenanceMode
        ? enableText
        : disableText;

      if (has(this.editing, "isInEmergencyMaintenanceMode")) {
        this.$modal.show("maintenanceModal");
      }
    },
    async doUpdate() {
      try {
        await this.updateDefaultSettings(this.updatedFields);
        this.$modal.hide("maintenanceModal");
        this.$appNotifySuccess(
          "Emergency maintenance mode data updated successfully"
        );
      } catch (error) {
        if (error !== "cancel") {
          this.$appNotify({
            message: error.message,
            type: "error",
            title: "Update Error"
          });
        }
        this.$bugSnagClient.notify(error);
      }
    }
  },
  computed: {
    ...settingMapState(["editing"]),
    ...authMapState(["initData"]),
    maintenanceModeText(): string {
      return this.editing?.isInEmergencyMaintenanceMode
        ? "emergencyMaintenanceEnabledHeader"
        : "emergencyMaintenanceDisabledHeader";
    },
    updatedFields(): any {
      const settings = {
        ...getSettingsDefaults({}),
        ...(this.getSettingsData || {})
      };
      return objectDifference(this.editing, settings, [
        "emergencyMaintenanceModeMessage"
      ]);
    },
    showSaveButton(): boolean {
      return Boolean(Object.keys(this.updatedFields).length > 0);
    },
    emergencyModeFormSchema() {
      return emergencyModeForm;
    },
    getSettingsData(): Partial<ISetting> | null {
      if (this.initData) {
        const {
          isDefault,
          emergencyMaintenanceModeMessage,
          isInEmergencyMaintenanceMode
        } = this.initData;
        return {
          isDefault,
          emergencyMaintenanceModeMessage,
          isInEmergencyMaintenanceMode
        };
      }
      return null;
    }
  }
});
